var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home_ranking"},[_c('div',{staticClass:"home_ranking_box"},[_c('div',{staticClass:"home_ranking_table"},[_c('a-table',{attrs:{"loading":_vm.rankingData.rankingLoading,"columns":_vm.rankingData.columns,"data-source":_vm.rankingData.data,"rowKey":(record, index) => index,"pagination":false},scopedSlots:_vm._u([{key:"name",fn:function(text, record){return [_c('div',{staticClass:"new_title",on:{"click":function($event){return _vm.toDetail(record)}}},[_c('div',{staticClass:"new_title_ic"},[(record.messageCount == 0)?_c('img',{staticClass:"qsy_img",attrs:{"src":require("@/assets/images/homeImg/new_b.png"),"alt":""}}):_c('img',{staticClass:"qsy_img",attrs:{"src":require("@/assets/images/homeImg/new_a.png"),"alt":""}})]),_c('div',{staticClass:"new_title_name"},[_vm._v(_vm._s(record.title))])])]}},{key:"createTime",fn:function(text){return [_c('span',[_vm._v(_vm._s(text))])]}},{key:"messageCount",fn:function(text){return [_c('span',[_vm._v(_vm._s(text === 0 ? "未读" : "已读"))])]}}])}),(_vm.rankingData.data.length != 0)?_c('div',{staticClass:"ranking_page_box"},[_c('div',{class:[
            'ranking_page_first',
            _vm.currentNumFirst ? '' : 'noCursor',
            _vm.rankingData.rankingLoading ? 'btn_disabled' : '',
          ],on:{"click":_vm.firstBtn}},[_vm._v(" 首页 ")]),_c('div',{staticClass:"ranking_page_center"},[_c('a-pagination',{attrs:{"total":_vm.rankingData.pagination.total,"pageSize":_vm.rankingData.pagination.pageSize,"disabled":_vm.rankingData.rankingLoading},on:{"change":_vm.changeRankPage},model:{value:(_vm.rankingData.pagination.current),callback:function ($$v) {_vm.$set(_vm.rankingData.pagination, "current", $$v)},expression:"rankingData.pagination.current"}})],1),_c('div',{class:[
            'ranking_page_end',
            _vm.currentNumEnd ? '' : 'noCursor',
            _vm.rankingData.rankingLoading ? 'btn_disabled' : '',
          ],on:{"click":_vm.endBtn}},[_vm._v(" 尾页 ")])]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }